export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'lpresupuestocli.update',
      {
        values: {
          idlpresupuestocli: id,
          codigo: formData.codigo,
          descripcion: formData.descripcion,
          inf_comercial: formData.inf_comercial,
          precio: formData.precio,
          dto: formData.dto,
          unidades: formData.unidades,
          opcional: formData.opcional,
          observacion: formData.observacion,
        },
      },
    )
  },
}
